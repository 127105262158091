import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton, IconButtonProps } from '@mui/material';
import styles from './CancelButton.module.scss';

export interface CancelButtonProps extends IconButtonProps {
   onClick: () => void;
}

const CancelButton = React.memo(({ onClick, ...rest }: CancelButtonProps): React.ReactNode => {
   const cancelClickEvent = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();

      if (onClick) {
         onClick();
      }
   };

   return (
      <IconButton
         {...rest}
         onClick={(event) => {
            cancelClickEvent(event);
         }}
         size="large"
      >
         <CancelIcon className={styles.root} />
      </IconButton>
   );
});

export default CancelButton;
export { CancelButton };
