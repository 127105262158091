import React from 'react';

import { Avatar } from '../../components/mui';
import { Agent } from '../../types';
import clsx from 'clsx';

import styles from './AgentAvatar.module.scss';

export interface AgentAvatarProps {
   className?: string;
   agent: Agent | null;
   size?: 'normal' | 'medium' | 'large';
   onClick?: () => void;
}

const AgentAvatar = React.memo(
   ({ className, agent, size = 'normal', onClick }: AgentAvatarProps): React.ReactNode | null => {
      const agentNameAbbr: string = React.useMemo(() => {
         if (agent) {
            const parts: string[] = agent.name.split(' ');

            return parts.map((p) => (p[0] || '').toUpperCase()).join('');
         }

         return '';
      }, [agent]);

      if (agent) {
         return (
            <Avatar title={agent.name} className={clsx(styles.root, styles[size], className)} onClick={onClick}>
               {agentNameAbbr}
            </Avatar>
         );
      }

      return null;
   },
);

export { AgentAvatar };
export default AgentAvatar;
