import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './Avatar.module.scss';

export interface AvatarProps extends HTMLAttributes<HTMLDivElement> {
   className?: string;
}

const Avatar = React.memo(({ children, className, ...rest }: AvatarProps): React.ReactNode => {
   return (
      <div className={clsx(styles.avatar, className)} {...rest}>
         {children}
      </div>
   );
});

export { Avatar };
export default Avatar;
