import React, { HTMLAttributes } from 'react';
import { createPortal } from 'react-dom';
import { createPopper, Placement } from '@popperjs/core';
import clsx from 'clsx';
import Paper, { PaperProps } from '../paper/Paper';

import styles from './Popover.module.scss';

export interface PopoverPosition {
   top: number;
   left: number;
}

export interface PopoverProps extends HTMLAttributes<HTMLDivElement> {
   anchorEl?: null | Element | HTMLElement;
   anchorPosition?: PopoverPosition;
   children?: React.ReactNode;
   elevation?: number;
   onClose?: () => void;
   open?: boolean;
   placement?: Placement;
   PaperProps?: Partial<PaperProps>;
}

const body: HTMLElement = document.body;

const Popover = React.memo(
   ({
      children,
      anchorEl,
      anchorPosition,
      elevation = 8,
      onClose = () => {},
      open = false,
      placement = 'bottom',
      PaperProps = {},
      ...rest
   }: PopoverProps): React.ReactNode | null => {
      const [opened, setOpened] = React.useState<boolean>(false);

      const paperRef = React.useRef<HTMLDivElement>(null);

      React.useEffect(() => {
         return () => document.removeEventListener('keyup', handleEscPress);
      }, []);

      React.useEffect(() => {
         if (open) {
            document.addEventListener('keyup', handleEscPress);

            if (anchorEl && paperRef.current) {
               createPopper(anchorEl, paperRef.current, {
                  placement,
               });
            }
         } else {
            document.removeEventListener('keyup', handleEscPress);
         }

         setOpened(!!open);
      }, [open]);

      function handleClick() {
         setOpened(false);
      }

      function handleEscPress(e: KeyboardEvent) {
         if (e.code === 'Escape' || e.key === 'Escape') {
            setOpened(false);
         }
      }

      function handleTransitionEnd(e: React.TransitionEvent<HTMLDivElement>) {
         if (e.propertyName === 'opacity') {
            if (!opened) {
               onClose();
            }
         }
      }

      if (!open) {
         return null;
      }

      return createPortal(
         <div
            role="presentation"
            className={clsx(styles.popover, opened ? styles.open : '')}
            onTransitionEnd={handleTransitionEnd}
            {...rest}
         >
            <div aria-hidden="true" className={styles.backdrop} onClick={handleClick} />
            <Paper
               ref={paperRef}
               elevation={elevation}
               {...PaperProps}
               className={clsx(styles.paper, PaperProps.className ? PaperProps.className : '')}
               style={{ ...(PaperProps.style ? PaperProps.style : {}) }}
            >
               {children}
            </Paper>
         </div>,
         body,
      );
   },
);

export { Popover };
export default Popover;
