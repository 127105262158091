import React from 'react';

import styles from './IconButtonBase.module.scss';
import { IconButton, IconButtonProps } from '@mui/material';
import clsx from 'clsx';

interface IconButtonBaseInterface {
   href?: string;
   to?: string;
   target?: string;
}

export type IconButtonBaseProps = IconButtonBaseInterface & IconButtonProps;

const IconButtonBase = React.memo(({ children, className, ...rest }: IconButtonBaseProps): React.ReactNode => {
   return (
      <IconButton className={clsx(styles.root, className)} size="large" {...rest}>
         {children}
      </IconButton>
   );
});

export { IconButtonBase };
export default IconButtonBase;
