import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './Paper.module.scss';

export interface PaperProps extends HTMLAttributes<HTMLDivElement> {
   elevation?: number;
   square?: boolean;
   hover?: boolean;
   ref?: React.RefObject<HTMLDivElement | null>;
}

const Paper = React.memo(
   ({ elevation, square, hover, ref, children, className = '', ...rest }: PaperProps): React.ReactNode => {
      const elevationClassName: string = styles[`elevation${elevation}`];
      const squareClassName: string = square ? styles.square : '';
      const hoverClassName: string = hover ? styles.hover : '';

      return (
         <div
            ref={ref}
            className={clsx(styles.paper, squareClassName, elevationClassName, hoverClassName, className)}
            {...rest}
         >
            {children}
         </div>
      );
   },
);

export { Paper };
export default Paper;
