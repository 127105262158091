import React from 'react';
import clsx from 'clsx';
import { Divider, Drawer, ListItem, ListItemText, Typography } from '@mui/material';
import { Agent, AgentRole } from '../../../types';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../routes/Routes';
import { AgentAvatar, RowBox } from '../../common';
import { Navigation } from '../../layout/navigation';
import { SettingsStore, useSettingsStore } from '../../../store';
import styles from './NavigationDrawer.module.scss';

export interface NavigationDrawerProps {
   open: boolean;
   agent: Agent | null;
}

const NavigationDrawer = React.memo(({ open, agent }: NavigationDrawerProps): React.ReactNode => {
   const navigate = useNavigate();

   const switchNavigationDrawer = useSettingsStore((state: SettingsStore) => state.switchNavigationDrawer);

   const agentRole: string = React.useMemo(() => {
      if (agent) {
         return agent.roles.filter((r: AgentRole) => r.active).map((r: AgentRole) => r.role.name)[0];
      }

      return '';
   }, [agent]);

   const agentRoles: string = React.useMemo(() => {
      if (agent) {
         return agent.roles
            .filter((r: AgentRole) => r.active)
            .map((r: AgentRole) => r.role.name)
            .join(', ');
      }

      return '';
   }, [agent]);

   function navigateToAgent() {
      if (agent) {
         navigate(`${Routes.ADMIN_AGENTS}/${agent.id}`);
      }
   }

   return (
      <Drawer
         variant="permanent"
         classes={{
            paper: clsx(styles.drawer_paper, !open && styles.drawer_paper_close),
         }}
         open={open}
      >
         <div className={styles.toolbar_icon}>
            <AgentAvatar
               agent={agent}
               className={clsx([styles.avatar, open ? styles.avatar_opened : ''])}
               onClick={switchNavigationDrawer}
            />
            {open && (
               <RowBox>
                  <ListItem className={styles.agent} button onClick={navigateToAgent} title={agentRoles}>
                     <ListItemText
                        primary={agent?.name}
                        secondary={
                           <RowBox>
                              <Typography variant="body2">{agentRole}</Typography>
                              <div className={styles.agent_role_dot}></div>
                              <Typography variant="body2" className={styles.agent_role_link_text}>
                                 Profile Settings
                              </Typography>
                           </RowBox>
                        }
                        primaryTypographyProps={{ className: styles.agent_info_text }}
                        secondaryTypographyProps={{ className: styles.agent_role_text }}
                     />
                  </ListItem>
               </RowBox>
            )}
         </div>
         <Divider />
         <Navigation collapsed={!open} />
      </Drawer>
   );
});

export default NavigationDrawer;
export { NavigationDrawer };
