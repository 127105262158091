import React from 'react';
import SearchResult from './SearchResult';
import { useNavigate } from 'react-router-dom';
import { CustomersService, PaginatedResponse } from '../../../services';
import { isEmpty, useInstance } from '@bridgemoney/core';
import { useRequest } from '../../../hooks';
import { Customer } from '../../../types';
import { Routes } from '../../../routes/Routes';
import { SearchField } from '../../common';
import { normalizePhoneNumber } from '../../../utils';

import styles from './QuickSearchBar.module.scss';

export interface QuickSearchBarProps {}

function QuickSearchBar(props: QuickSearchBarProps): React.ReactNode {
   const [search, setSearch] = React.useState<string>('');

   const navigate = useNavigate();

   const service: CustomersService = useInstance<CustomersService>('CustomersService');
   const quickSearch = useRequest<PaginatedResponse<Customer>>(service.quickSearch.bind(service, search || ''));

   React.useEffect(() => {
      if (!isEmpty(search)) {
         quickSearch.run();
      }
   }, [search]);

   React.useEffect(() => {
      if (quickSearch.data) {
         if (quickSearch.data.records.length === 1) {
            navigateToMember(quickSearch.data.records[0].id);
         }
      }
   }, [quickSearch.data]);

   const handleSearchChange = (text: string) => {
      setSearch(normalizePhoneNumber(text));
   };

   const clearSearch = () => {
      quickSearch.clear();
   };

   const navigateToMember = (id: string): void => {
      clearSearch();
      navigate(`${Routes.CAFE_MEMBERS}/${id}`);
   };

   function renderResult() {
      if (quickSearch.data) {
         if (quickSearch.data?.records.length > 1) {
            return <SearchResult data={quickSearch.data.records} onClick={navigateToMember} />;
         }

         return <SearchResult data={[]} onClick={navigateToMember} />;
      }

      return null;
   }

   return (
      <SearchField
         id="quick_search"
         onChangeText={handleSearchChange}
         onClearText={clearSearch}
         className={styles.root}
         value={search}
      >
         {renderResult()}
      </SearchField>
   );
}

export default React.memo(QuickSearchBar);
