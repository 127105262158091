import React, { PropsWithChildren } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { BaseLayout } from './BaseLayout';
import { Avatar, Paper } from '../components/mui';

import styles from './PublicLayout.module.scss';

export interface PublicLayoutProps extends PropsWithChildren<any> {}

const PublicLayout = React.memo(({ children }: PublicLayoutProps): React.ReactNode => {
   return (
      <BaseLayout>
         <div className={styles.root}>
            <main className={styles.main}>
               <Paper className={styles.paper} elevation={10}>
                  <Avatar className={styles.avatar}>
                     <LockOutlinedIcon />
                  </Avatar>
                  {children}
               </Paper>
            </main>
         </div>
      </BaseLayout>
   );
});

export default PublicLayout;
export { PublicLayout };
