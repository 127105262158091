import React, { HTMLAttributes } from 'react';

import { localDatetimeToServerDatetime } from '../../../utils';
import clsx from 'clsx';
import { Popover } from '../../mui';

import styles from './Time.module.scss';
import { Divider } from '@mui/material';

export interface TimeProps extends HTMLAttributes<HTMLDivElement> {}

const Time = React.memo(({ className, onClick = () => {}, ...rest }: TimeProps): React.ReactNode => {
   const [timeWidgetOpened, setTimeWidgetOpened] = React.useState<boolean>(false);
   const [time, setTime] = React.useState<Date>(new Date());

   const rootRef = React.useRef<HTMLDivElement>(null);
   const timerRef = React.useRef<any>(0);

   React.useEffect(() => {
      tick();

      return () => clearTimeout(timerRef.current);
   }, []);

   function handleClick(e: any) {
      setTimeWidgetOpened(true);

      onClick(e);
   }

   function closeTimeWidget() {
      setTimeWidgetOpened(false);
   }

   function tick() {
      timerRef.current = setTimeout(() => {
         clearTimeout(timerRef.current);
         setTime(new Date());
         tick();
      }, 1000);
   }

   return (
      <>
         <div className={clsx(styles.time, className)} ref={rootRef} onClick={handleClick} {...rest}>
            <span className={styles.hours}>{localDatetimeToServerDatetime(time, 'h:mm A z')}</span>
            <span className={styles.date}>{localDatetimeToServerDatetime(time, 'MMM DD')}</span>
         </div>
         <Popover
            open={timeWidgetOpened && Boolean(rootRef.current)}
            anchorEl={rootRef.current}
            onClose={closeTimeWidget}
            placement="left-start"
         >
            <div className={styles.fullTimeWrapper}>
               <h3 className={styles.fullTimeTitle}>Bridge Time (CDT)</h3>
               <Divider className={styles.divider} />
               <p className={styles.fullTimeDate}>{localDatetimeToServerDatetime(time, 'dddd D MMMM YYYY')}</p>
               <p className={styles.fullTimeHours}>{localDatetimeToServerDatetime(time, 'h:mm:ss')}</p>
            </div>
         </Popover>
      </>
   );
});

export { Time };
export default Time;
