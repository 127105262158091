import React, { PropsWithChildren } from 'react';
import { InputBase, InputBaseProps } from '@mui/material';
import { CancelButton } from './CancelButton';
import clsx from 'clsx';

import styles from './SearchField.module.scss';

export interface SearchFieldProps extends PropsWithChildren<any> {
   className?: string;
   cancelButtonClassName?: string;
   id: string;
   inputProps?: InputBaseProps;
   onChangeText: (text: string) => void;
   onPressEnter?: () => void;
   onClearText?: () => void;
   placeholder?: string;
   value?: string;
   noDelay?: boolean;
}

const SearchField = React.memo(
   ({
      children,
      className = '',
      cancelButtonClassName = '',
      id,
      inputProps = {},
      onChangeText,
      onClearText = () => {},
      onPressEnter = () => {},
      placeholder = 'Quick Search by UUID, Email, or Phone',
      value = '',
      noDelay = false,
      ...rest
   }: SearchFieldProps & InputBaseProps): React.ReactNode => {
      const [search, setSearch] = React.useState<string>(value || '');
      const [busy, setBusy] = React.useState<boolean>(false);

      const timeoutRef = React.useRef<number>(0);
      const onEnterPressQueuedRef = React.useRef<boolean>(false);

      React.useEffect(() => {
         setSearch(value || '');
      }, [value]);

      React.useEffect(() => {
         if (!busy && onEnterPressQueuedRef.current && onPressEnter) {
            onEnterPressQueuedRef.current = false;
            setTimeout(onPressEnter, 250);
         }
      }, [busy, onPressEnter]);

      React.useEffect(() => {
         if (noDelay) {
            onChangeText(search);
         } else {
            clearTimeout(timeoutRef.current);
            setBusy(true);

            timeoutRef.current = window.setTimeout(() => {
               if (search !== null) {
                  onChangeText(search);
                  setBusy(false);
               }
            }, 500);
         }
      }, [search, noDelay]);

      const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
         setSearch(event.target.value);
      };

      const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
         if (event.key === 'Enter' && onPressEnter) {
            if (busy) {
               onEnterPressQueuedRef.current = true;
            } else {
               onPressEnter();
            }
         }
      };

      const clearSearch = () => {
         setSearch('');

         if (onClearText) {
            onClearText();
         }
      };

      return (
         <div className={clsx(styles.root, className)}>
            <div className={styles.input_wrapper}>
               <img src="/icons/search.png" alt="search icon" className={styles.icon} />

               <InputBase
                  {...rest}
                  id={id}
                  fullWidth
                  value={search}
                  onChange={handleSearchChange}
                  onKeyUp={handleKeyUp}
                  inputProps={{ 'aria-label': placeholder, placeholder, className: styles.input }}
                  {...(inputProps || {})}
               />
            </div>

            <CancelButton onClick={clearSearch} className={cancelButtonClassName} />

            {children}
         </div>
      );
   },
);

export default SearchField;
export { SearchField };
