import React from 'react';
import { Typography } from '@mui/material';
import { Route } from '../../../routes/Routes';
import { RowBox } from '../../common';
import styles from './ToolbarLeft.module.scss';

export interface ToolbarLeftProps {
   onMenuClick: () => void;
   activeRoute: Route | null | undefined;
   drawerOpened: boolean;
}

const ToolbarLeft = React.memo(({ activeRoute }: ToolbarLeftProps): React.ReactNode => {
   return (
      <RowBox>
         <Typography component="h1" variant="h5" color="textPrimary" noWrap className={styles.title} display="inline">
            {!!activeRoute && activeRoute.title}
         </Typography>
         <div id="breadcrumbs-root"></div>
      </RowBox>
   );
});

export default ToolbarLeft;
export { ToolbarLeft };
