// @ts-nocheck
import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';
import { capitalize } from '../../../utils';

import styles from './Button.module.scss';

interface CommonButtonProps {
   color?: 'primary' | 'secondary' | 'default' | 'danger';
   disableElevation?: boolean;
   disableFocusRipple?: boolean;
   disabled?: boolean;
   endIcon?: React.ReactNode;
   fullWidth?: boolean;
   href?: string | never;
   size?: 'small' | 'medium' | 'large';
   startIcon?: React.ReactNode;
   variant?: 'text' | 'outlined' | 'contained';
   labelClassName?: string;
}

export type ButtonProps = CommonButtonProps &
   (
      | ({ href: string } & AnchorHTMLAttributes<HTMLAnchorElement>)
      | ({ href?: never } & ButtonHTMLAttributes<HTMLButtonElement>)
   );

const Button = React.memo(
   ({
      children,
      className,
      color = 'primary',
      disableElevation = false,
      disableFocusRipple = false,
      disabled = false,
      endIcon,
      fullWidth = false,
      href,
      onClick = () => {},
      size = 'medium',
      startIcon,
      tabIndex,
      variant = 'text',
      type = 'button',
      labelClassName = '',
      ...rest
   }: ButtonProps): React.ReactNode => {
      const buttonRef = React.useRef<any | null>(null);

      function createRipple(e: any) {
         if (buttonRef.current) {
            const button = buttonRef.current;
            const rect: DOMRect = button.getBoundingClientRect();
            const diameter = Math.max(rect.width, rect.height);
            const radius = diameter / 2;

            const ripple = document.createElement('span');
            ripple.classList.add(styles.ripple);
            ripple.style.width = ripple.style.height = `${diameter}px`;
            ripple.style.left = `${e.clientX - (rect.left + radius)}px`;
            ripple.style.top = `${e.clientY - (rect.top + radius)}px`;
            ripple.onanimationend = removeRipple.bind(null, ripple);

            buttonRef.current?.appendChild(ripple);
         }

         onClick(e);
      }

      function removeRipple(element: HTMLElement) {
         if (element) {
            element.remove();
         }
      }

      const classes = clsx(
         styles.button,
         styles[variant],
         styles[`${variant}${capitalize(color)}`],
         styles[`size${capitalize(size)}`],
         disableElevation && styles.disableElevation,
         fullWidth && styles.fullWidth,
         className,
      );

      if (href) {
         return (
            <a ref={(ref: any) => (buttonRef.current = ref)} className={classes} href={href} {...rest}>
               {startIcon && <span className={styles.startIcon}>{startIcon}</span>}
               <span className={styles.label}>{children}</span>
               {endIcon && <span className={styles.endIcon}>{endIcon}</span>}
            </a>
         );
      }

      return (
         <button
            ref={(ref: HTMLButtonElement | null) => (buttonRef.current = ref)}
            className={classes}
            disabled={disabled}
            tabIndex={disabled ? -1 : tabIndex}
            onClick={createRipple}
            type={type}
            {...rest}
         >
            {startIcon && <span className={styles.startIcon}>{startIcon}</span>}
            {children ? <span className={clsx(styles.label, labelClassName)}>{children}</span> : null}
            {endIcon && <span className={children ? styles.endIcon : ''}>{endIcon}</span>}
         </button>
      );
   },
);

export { Button };
export default Button;
