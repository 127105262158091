import React from 'react';
import clsx from 'clsx';

import styles from './CircularProgress.module.scss';

export type CircularProgressColor = 'primary' | 'accent' | 'secondary' | 'success' | 'info' | 'warning' | 'error';

export interface CircularProgressProps {
   size?: number;
   className?: string;
   color?: CircularProgressColor;
}

const CircularProgress = React.memo(({ size, className, color = 'accent' }: CircularProgressProps): React.ReactNode => {
   const style: any = {};
   const childStyle: any = {};

   if (size) {
      style.width = size;
      style.height = size;
      childStyle.borderWidth = size / 6;
      childStyle.width = size;
      childStyle.height = size;
   }

   return (
      <div className={clsx(styles.circular_progress, styles[color], className)} style={style}>
         <div className={styles.darker} style={childStyle} />
         <div className={styles.lighter} style={childStyle} />
      </div>
   );
});

export { CircularProgress };
export default CircularProgress;
