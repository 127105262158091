import React, { PropsWithChildren } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { ConfirmationStore, ToastStore, useConfirmationStore, useToastStore } from '../store';
import { ConfirmationDialog } from '../components/common/dialogs';
import styles from './BaseLayout.module.scss';
import BatchWorkflowsWidget from '../components/workflows/batch/BatchWorkflowsWidget';

export interface BaseLayoutProps extends PropsWithChildren<any> {}

const BaseLayout = React.memo(({ children }: BaseLayoutProps): React.ReactNode => {
   const toast = useToastStore((state: ToastStore) => ({
      severity: state.severity,
      message: state.message,
      helperText: state.helperText,
      setMessage: state.setMessage,
      setHelperText: state.setHelperText,
   }));

   const confirmation = useConfirmationStore((state: ConfirmationStore) => ({
      opened: state.opened,
      title: state.title,
      severity: state.severity,
      message: state.message,
      onAction: state.onAction,
      children: state.children,
   }));

   function clearToastMessage() {
      toast.setMessage(null, null);
      toast.setHelperText(null);
   }

   return (
      <React.Fragment>
         {children}
         <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={toast.message !== null}
            onClose={clearToastMessage}
            autoHideDuration={toast.severity === 'error' ? 60000 : 2000}
            key="topcenter"
         >
            <Alert
               iconMapping={{
                  error: (
                     <img className={styles.icon} src="/icons/urgent_notification.png" alt="urgent notification icon" />
                  ),
                  success: (
                     <img
                        className={styles.icon}
                        src="/icons/success_notification.png"
                        alt="success notification icon"
                     />
                  ),
                  warning: (
                     <img
                        className={styles.icon}
                        src="/icons/warning_notification.png"
                        alt="warning notification icon"
                     />
                  ),
                  info: (
                     <img
                        className={styles.icon}
                        src="/icons/success_notification.png"
                        alt="success notification icon"
                     />
                  ),
               }}
               variant="filled"
               onClose={clearToastMessage}
               severity={toast.severity || 'success'}
            >
               <p className={styles.alert_message}>{toast.message}</p>
               {toast.helperText ? <p className={styles.alert_helper_text}>{toast.helperText}</p> : null}
            </Alert>
         </Snackbar>

         <ConfirmationDialog
            open={confirmation.opened}
            title={confirmation.title || ''}
            description={confirmation.message || ''}
            icon={confirmation.severity}
            onAction={confirmation.onAction}
         >
            {confirmation.children}
         </ConfirmationDialog>

         <BatchWorkflowsWidget />
      </React.Fragment>
   );
});

export default BaseLayout;
export { BaseLayout };
