import React, { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { Permissions, PermissionsService } from '../services';
import { useInstance } from '@bridgemoney/core';
import { UserStore, useUserStore } from '../store';
import { Routes } from './Routes';

export interface PermissionRouteProps extends PropsWithChildren<any> {
   permissions: Permissions[];
}

const PermissionRoute = React.memo(({ permissions, children }: PermissionRouteProps): React.ReactNode => {
   const permissionService = useInstance<PermissionsService>('PermissionsService');
   const agent = useUserStore((state: UserStore) => state.agent);

   if (!!agent && permissionService.can(agent, permissions)) {
      return children;
   }

   return <Navigate to={Routes.ACCESS_DENIED} />;
});

export { PermissionRoute };
export default PermissionRoute;
