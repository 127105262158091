import React from 'react';

import { BatchWorkflowsStore, useBatchWorkflowsStore } from '../../../store';
import { BatchWorkflow, BatchWorkflowStatus } from '../../../types';
import styles from './BatchWorkflowsWidget.module.scss';
import { Alert } from '@mui/material';
import { CircularProgress } from '../../mui';
import { RowBox } from '../../common';
import { InfoBlock } from '../../user/InfoBlock';
import { capitalize } from '../../../utils';
import { useInterval } from '../../../hooks';
import { useCheckBatchWorkflowStatus } from '../../../store/actions';

export interface BatchWorkflowsWidgetProps {}

const statusToSeverityMap: any = {
   [BatchWorkflowStatus.COMPLETE]: 'success',
   [BatchWorkflowStatus.INITIALIZING]: 'info',
   [BatchWorkflowStatus.OPERATING]: 'error',
   [BatchWorkflowStatus.FINALIZING]: 'warning',
};

const BatchWorkflowsWidget = React.memo((props: BatchWorkflowsWidgetProps): React.ReactNode => {
   const queue = useBatchWorkflowsStore((state: BatchWorkflowsStore) => state.queue);
   const removeWorkflowFromQueue = useBatchWorkflowsStore(
      (state: BatchWorkflowsStore) => state.removeWorkflowFromQueue,
   );

   const timer = useInterval();
   const { checkBatchWorkflowStatus } = useCheckBatchWorkflowStatus();

   React.useEffect(() => {
      if (queue.length > 0) {
         timer.clearInterval();
         timer.interval(checkStatuses, 2000);
      } else {
         timer.clearInterval();
      }
   }, [queue]);

   function checkStatuses() {
      queue.forEach((w: BatchWorkflow) => {
         checkBatchWorkflowStatus(w.id);
      });
   }

   return (
      <div className={styles.root}>
         {queue.map((w: BatchWorkflow) => {
            return (
               <Alert
                  key={w.id}
                  iconMapping={{
                     error: <CircularProgress size={24} color="error" />,
                     success: <CircularProgress size={24} color="success" />,
                     warning: <CircularProgress size={24} color="warning" />,
                     info: <CircularProgress size={24} color="info" />,
                  }}
                  variant="filled"
                  onClose={removeWorkflowFromQueue.bind(null, w)}
                  severity={statusToSeverityMap[w.status]}
               >
                  <RowBox className={styles.row_box}>
                     <InfoBlock
                        caption="Batch Workflow"
                        description={w.name}
                        captionProps={{
                           className: styles.caption,
                        }}
                        descriptionProps={{
                           className: styles.description,
                        }}
                     />
                     <InfoBlock
                        caption="Status"
                        description={capitalize(w.status)}
                        captionProps={{
                           className: styles.caption,
                        }}
                        descriptionProps={{
                           className: styles.description,
                        }}
                     />
                  </RowBox>
               </Alert>
            );
         })}
      </div>
   );
});

export { BatchWorkflowsWidget };
export default BatchWorkflowsWidget;
