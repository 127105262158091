import { Route, Routes, Navigate } from 'react-router-dom';
import React, { Suspense } from 'react';
import { Routes as AppRoutes } from './Routes';
import { PublicLayout } from '../layout';
import { OrbitalProgress } from '../components/mui';

const Login = React.lazy(() => import('../pages/Login'));
const PasswordReset = React.lazy(() => import('../pages/PasswordReset'));
const AuthorizedPasswordReset = React.lazy(() => import('../pages/AuthorizedPasswordReset'));

function PublicRoutes(): React.ReactNode {
   return (
      <PublicLayout>
         <Suspense fallback={<OrbitalProgress overlay />}>
            <Routes>
               <Route path="*" element={<Navigate to={AppRoutes.LOGIN} />} />
               <Route path={AppRoutes.LOGIN} element={<Login />} />
               <Route index={false} path={AppRoutes.PASSWORD_RESET} element={<PasswordReset />} />
               <Route index={false} path={AppRoutes.AUTHORIZED_PASSWORD_RESET} element={<AuthorizedPasswordReset />} />
               <Route path={AppRoutes.ROOT} element={<Navigate to={AppRoutes.LOGIN} />} />
            </Routes>
         </Suspense>
      </PublicLayout>
   );
}

export default PublicRoutes;
export { PublicRoutes };
