import React from 'react';
import { Route } from 'routes/Routes';
import { List, ListItem, ListItemText } from '@mui/material';
import { NavigationLink } from './NavigationLink';
import { Agent } from 'types';
import clsx from 'clsx';
import { PermissionsService } from 'services';
import { useInstance } from '@bridgemoney/core';
import styles from './NavigationNamespace.module.scss';
import { SettingsStore, useSettingsStore } from '../../../store';

export interface NavigationNamespaceProps {
   title: string;
   routes: Route[];
   activeRoute: Route | null;
   agent: Agent | null;
   collapsed?: boolean;
   icon: string;
}

const NavigationNamespace = React.memo(
   ({
      title,
      routes,
      activeRoute,
      agent,
      icon,
      collapsed = false,
   }: NavigationNamespaceProps): React.ReactNode | null => {
      const switchNavigationDrawer = useSettingsStore((state: SettingsStore) => state.switchNavigationDrawer);
      const navigationDrawerOpened = useSettingsStore((state: SettingsStore) => state.navigationDrawerOpened);

      const service: PermissionsService = useInstance<PermissionsService>('PermissionsService');

      function isRouteAvailableForAgent(route: Route): boolean {
         if (agent && agent.permissions && agent.permissions.length > 0) {
            return service.can(agent, [route.permission]);
         }

         return true;
      }

      if (agent) {
         const availableRoutes = routes.filter(isRouteAvailableForAgent);

         if (availableRoutes.length > 0) {
            return (
               <>
                  <ListItem
                     button
                     onClick={switchNavigationDrawer}
                     className={clsx(styles.root_list_item)}
                     title={title}
                     disableGutters
                  >
                     <img src={icon} alt={`${title} icon`} className={styles.icon} />
                     <ListItemText
                        primary={title}
                        primaryTypographyProps={{
                           className: clsx(styles.title, collapsed ? styles.collapsed_title : ''),
                        }}
                     />
                  </ListItem>
                  <List className={styles.list} disablePadding>
                     {availableRoutes.map((route: Route) => {
                        return (
                           <NavigationLink
                              route={route}
                              activeRoute={activeRoute}
                              key={route.route}
                              titleVisible={navigationDrawerOpened}
                           />
                        );
                     })}
                  </List>
               </>
            );
         } else {
            return null;
         }
      }

      return null;
   },
);

export default NavigationNamespace;
export { NavigationNamespace };
