import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import styles from './SplitPane.module.scss';

export type SplitDirection = 'horizontal' | 'vertical';

type SplitClientAxis = 'clientX' | 'clientY';
type SplitClientSize = 'clientWidth' | 'clientHeight';
type SplitClientPosition = 'left' | 'top';
type SplitClientPositionEnd = 'right' | 'bottom';
type SplitClientDimension = 'width' | 'height';

interface SplitClientData {
   dimension: SplitClientDimension;
   clientAxis: SplitClientAxis;
   position: SplitClientPosition;
   positionEnd: SplitClientPositionEnd;
   clientSize: SplitClientSize;
}

export interface SplitPaneProps extends PropsWithChildren<any> {
   direction?: SplitDirection;
   sizes?: number[];
}

const GUTTER_WIDTH: number = 10;

const SplitPane = React.memo(
   ({ children, direction = 'horizontal', sizes = [50, 50] }: SplitPaneProps): React.ReactNode => {
      const [internalSizes, setInternalSizes] = React.useState<number[]>(sizes || []);
      const [mouseX, setMouseX] = React.useState<number | null>(null);
      const [isDragging, setIsDragging] = React.useState<boolean>(false);
      const [clientData, setClientData] = React.useState<SplitClientData>(getClientData(direction));
      const [start, setStart] = React.useState<number>(0);

      const paneRef = React.useRef<HTMLDivElement>(null);

      React.useEffect(() => {
         setInternalSizes(sizes || []);
      }, [sizes]);

      React.useEffect(() => {
         setClientData(getClientData(direction));
      }, [direction]);

      React.useEffect(() => {
         if (paneRef.current) {
            setStart(paneRef.current?.getBoundingClientRect()[clientData.position]);
         }
      }, [children]);

      const directionClassName: string = styles[direction || ''] || '';
      const realChildren: any[] = React.Children.toArray(children).filter((c) => c !== null);
      const childrenCount: number = React.Children.count(realChildren);

      function getClientData(direction?: SplitDirection): SplitClientData {
         if (direction === 'horizontal') {
            return {
               clientAxis: 'clientX',
               position: 'left',
               positionEnd: 'right',
               clientSize: 'clientWidth',
               dimension: 'width',
            };
         }

         return {
            clientAxis: 'clientY',
            position: 'top',
            positionEnd: 'bottom',
            clientSize: 'clientHeight',
            dimension: 'height',
         };
      }

      function getMousePosition(e: React.MouseEvent<HTMLElement>) {
         if ('touches' in e) {
            // @ts-ignore
            return e.touches[0][clientData.clientAxis];
         }

         return e[clientData.clientAxis];
      }

      function toPaneClientAxis(e: React.MouseEvent<HTMLElement>): number {
         return getMousePosition(e) - start + GUTTER_WIDTH;
      }

      function handleMouseDown(e: React.MouseEvent<HTMLElement>) {
         switchSelection(false);
         setMouseX(toPaneClientAxis(e));
         setIsDragging(true);
      }

      function handleMouseUp(e: React.MouseEvent<HTMLElement>) {
         switchSelection();
         setMouseX(toPaneClientAxis(e));
         setIsDragging(false);
      }

      function handleMouseMove(e: React.MouseEvent<HTMLElement>) {
         if (isDragging && paneRef.current && mouseX) {
            const newInternalSizes = internalSizes.slice();

            newInternalSizes[0] = (toPaneClientAxis(e) * 100) / paneRef.current?.offsetWidth;
            newInternalSizes[1] = 100 - newInternalSizes[0];

            setInternalSizes(newInternalSizes);
         }
      }

      function switchSelection(enabled: boolean = true) {
         if (paneRef.current) {
            const children = paneRef.current?.children;
            Array.from(children).forEach((el: any) => {
               if (el.getAttribute('id') === null || el.getAttribute('id').indexOf('gutter') === -1) {
                  el.style.userSelect = enabled ? '' : 'none';
                  el.style.webkitUserSelect = enabled ? '' : 'none';
                  el.style.MozUserSelect = enabled ? '' : 'none';
                  el.style.pointerEvents = enabled ? '' : 'none';
               }
            });
         }
      }

      return (
         <div
            className={clsx(styles.root, directionClassName)}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            ref={paneRef}
         >
            {React.Children.map(realChildren, (child, index: number) => {
               const isSingleElement: boolean = index === 0 && index === childrenCount - 1;
               const dimension: string = isSingleElement
                  ? '100%'
                  : `calc(${internalSizes[index]}% - ${GUTTER_WIDTH / 2}px)`;
               const style: any = {
                  [clientData.dimension]: dimension,
               };

               return (
                  <>
                     <div style={style}>{child}</div>
                     {index < childrenCount - 1 && (
                        <div id={`gutter_${index}`} className={styles.gutter} onMouseDown={handleMouseDown} />
                     )}
                  </>
               );
            })}
         </div>
      );
   },
);

export default SplitPane;
export { SplitPane };
