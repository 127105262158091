import React from 'react';
import { createPortal } from 'react-dom';

import styles from './OrbitalProgress.module.scss';

export interface OrbitalProgressProps {
   overlay?: boolean;
}

const body = document.body;

const OrbitalProgress = React.memo(({ overlay = false }: OrbitalProgressProps): React.ReactNode => {
   function renderLoader() {
      return (
         <div className={styles.loader}>
            <div className={styles.mainCircle}>
               <div className={styles.bigSpinner} />
               <div className={styles.smallSpinner} />
            </div>
            <div className={styles.orbitCircleContainer}>
               <div className={styles.orbitCircle} />
            </div>
            <div className={styles.orbitCircleContainer}>
               <div className={styles.orbitCircle} />
            </div>
         </div>
      );
   }

   if (overlay) {
      return createPortal(
         <div role="presentation" className={styles.overlay}>
            {renderLoader()}
         </div>,
         body,
      );
   }

   return renderLoader();
});

export { OrbitalProgress };
export default OrbitalProgress;
