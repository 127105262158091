import React from 'react';
import { InstructionsText, InstructionsTextAnnotations } from 'types';
import { Link } from '@mui/material';
import styles from './Text.module.scss';

export interface TextProps {
   text: InstructionsText[];
}

function getTextStyles(annotations: InstructionsTextAnnotations): React.CSSProperties {
   const styles: React.CSSProperties = {};

   if (annotations.bold) {
      styles.fontWeight = 'bold';
   }

   if (annotations.italic) {
      styles.fontStyle = 'italic';
   }

   if (annotations.underline) {
      styles.textDecoration = 'underline';
   }

   if (annotations.strikethrough) {
      styles.textDecoration = 'line-through';
   }

   if (annotations.color && annotations.color !== 'default') {
      styles.color = annotations.color;
   }

   return styles;
}

const Text = React.memo(({ text = [] }: TextProps): React.ReactNode => {
   const children = text.map((t: InstructionsText, index: number) => {
      if (t.href) {
         return (
            <Link key={`${t.plain_text}_${index}`} target="_blank" href={t.href} style={getTextStyles(t.annotations)}>
               {t.plain_text}
            </Link>
         );
      }

      return React.createElement(
         t.annotations.code ? 'code' : 'span',
         {
            key: `${t.plain_text}_${index}`,
            style: getTextStyles(t.annotations),
            className: t.annotations.code ? styles.code : '',
         },
         t.plain_text,
      );
   });

   return <>{children}</>;
});

export default Text;
export { Text };
