import React from 'react';
import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';
import { isEmpty } from '@bridgemoney/core';
import styles from './RowBox.module.scss';
import { CopyToClipboardButton } from './buttons/CopyToClipboardButton';

export interface RowBoxProps extends BoxProps {
   valueToCopy?: string;
   hover?: boolean;
}

const RowBox = React.memo(
   ({ className, children, valueToCopy, hover = false, ...rest }: RowBoxProps): React.ReactNode => {
      const withCopy: boolean = !isEmpty(valueToCopy);

      return (
         <Box className={clsx(styles.row, className)} {...rest}>
            {children}
            {withCopy && <CopyToClipboardButton className={styles.copy_button} value={valueToCopy || ''} />}
         </Box>
      );
   },
);

export default RowBox;
export { RowBox };
